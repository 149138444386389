import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "register",
    path: `${AUTH_PREFIX_PATH}/register`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
  {
    key: "reset-new-password",
    path: `${AUTH_PREFIX_PATH}/reset-new-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/confirmation-new-password")
    ),
  },
  {
    key: "success-password",
    path: `${AUTH_PREFIX_PATH}/success-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/success-result")
    ),
  },
];

export const protectedRoutes = [
  {
    key: "dashboard",
    path: `${APP_PREFIX_PATH}/shipper/dashboard/`,
    component: React.lazy(() => import("views/app-views/shipper/dashboard")),
  },
  {
    key: "orders",
    path: `${APP_PREFIX_PATH}/shipper/orders/`,
    component: React.lazy(() => import("views/app-views/shipper/orders")),
  },
  {
    key: "orders.shipper.details",
    path: `${APP_PREFIX_PATH}/shipper/orders/details/:orderId/`,
    component: React.lazy(() =>
      import("views/app-views/orderDetails/OrderDetails")
    ),
  },
  {
    key: "success.payment",
    path: `${APP_PREFIX_PATH}/shipper/orders/success-payment/`,
    component: React.lazy(() =>
      import("views/app-views/shipper/orders/newOrder/stripe/SuccessPayment")
    ),
  },
  {
    key: "addressess",
    path: `${APP_PREFIX_PATH}/shipper/addresses/`,
    component: React.lazy(() => import("views/app-views/shipper/addresses")),
  },
  {
    key: "settings",
    path: `${APP_PREFIX_PATH}/shipper/settings/`,
    component: React.lazy(() =>
      import("views/app-views/shipper/account-settings")
    ),
  },
  {
    key: "carrier.dashboard",
    path: `${APP_PREFIX_PATH}/carrier/dashboard/`,
    component: React.lazy(() => import("views/app-views/carrier/dashboard")),
  },
  {
    key: "carrier.orders",
    path: `${APP_PREFIX_PATH}/carrier/orders/`,
    component: React.lazy(() => import("views/app-views/carrier/orders")),
  },
  {
    key: "orders.carrier.details",
    path: `${APP_PREFIX_PATH}/carrier/orders/details/:orderId/`,
    component: React.lazy(() =>
      import("views/app-views/orderDetails/OrderDetails")
    ),
  },
  {
    key: "carrier.payments",
    path: `${APP_PREFIX_PATH}/carrier/payments/`,
    component: React.lazy(() => import("views/app-views/carrier/payments")),
  },
  {
    key: "carrier.settings",
    path: `${APP_PREFIX_PATH}/carrier/settings/`,
    component: React.lazy(() =>
      import("views/app-views/carrier/account-settings")
    ),
  },
  {
    key: "admin.dashboard",
    path: `${APP_PREFIX_PATH}/admin/dashboard/`,
    component: React.lazy(() => import("views/app-views/admin/dashboard")),
  },
  {
    key: "admin.orders",
    path: `${APP_PREFIX_PATH}/admin/orders/`,
    component: React.lazy(() => import("views/app-views/admin/orders")),
  },
  {
    key: "admin.shippers",
    path: `${APP_PREFIX_PATH}/admin/shippers/`,
    component: React.lazy(() => import("views/app-views/admin/shippers")),
  },
  {
    key: "admin.carriers",
    path: `${APP_PREFIX_PATH}/admin/carriers/`,
    component: React.lazy(() => import("views/app-views/admin/carriers")),
  },
  {
    key: "admin.shippers.payments",
    path: `${APP_PREFIX_PATH}/admin/shippers-payments/`,
    component: React.lazy(() =>
      import("views/app-views/admin/shippers-payments")
    ),
  },
  {
    key: "admin.carriers.payments",
    path: `${APP_PREFIX_PATH}/admin/carriers-payments/`,
    component: React.lazy(() =>
      import("views/app-views/admin/carriers-payments")
    ),
  },
  {
    key: "admin.settings",
    path: `${APP_PREFIX_PATH}/admin/settings/`,
    component: React.lazy(() =>
      import("views/app-views/admin/account-settings")
    ),
  },
  {
    key: "shipper.new.order",
    path: `${APP_PREFIX_PATH}/shipper/orders/new/`,
    component: React.lazy(() =>
      import("views/app-views/shipper/orders/newOrder/AddNewOrder")
    ),
  },
  {
    key: "error",
    path: `${APP_PREFIX_PATH}/error/`,
    component: React.lazy(() =>
      import("views/auth-views/errors/error-page-1/index")
    ),
  },
  {
    key: "shipper.setup.success",
    path: `${APP_PREFIX_PATH}/shipper/setup/`,
    component: React.lazy(() => import("views/app-views/shipper/setup/index")),
  },
  {
    key: "success.setup",
    path: `${APP_PREFIX_PATH}/shipper/success-setup/`,
    component: React.lazy(() =>
      import("views/app-views/shipper/setup/SuccessSetup")
    ),
  },
  {
    key: "shipper.setup.verification.progress",
    path: `${APP_PREFIX_PATH}/shipper/verification-progress/`,
    component: React.lazy(() =>
      import("views/app-views/setup-pages/VerificationProgress")
    ),
  },
  {
    key: "shipper.setup.error",
    path: `${APP_PREFIX_PATH}/shipper/setup-error-page/`,
    component: React.lazy(() =>
      import("views/app-views/setup-pages/ErrorSetupPage")
    ),
  },
  {
    key: "carrier.setup.error",
    path: `${APP_PREFIX_PATH}/carrier/setup-error-page/`,
    component: React.lazy(() =>
      import("views/app-views/setup-pages/ErrorSetupPage")
    ),
  },
  {
    key: "carrier.setup.verification.progress",
    path: `${APP_PREFIX_PATH}/carrier/verification-progress/`,
    component: React.lazy(() =>
      import("views/app-views/setup-pages/VerificationProgress")
    ),
  },
];
