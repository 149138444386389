const dev = {
  API_ENDPOINT_URL: process.env.REACT_APP_DEV_API_ENDPOINT_URL,
  API_AUTH_URL: process.env.REACT_APP_DEV_API_AUTH_URL,
};

const prod = {
  API_ENDPOINT_URL: process.env.REACT_APP_PROD_API_ENDPOINT_URL,
  API_AUTH_URL: process.env.REACT_APP_PROD_API_AUTH_URL,
};

const test = {
  API_ENDPOINT_URL: "/api",
  API_AUTH_URL: "/auth",
};

const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case "development":
      return dev;
    case "production":
      return prod;
    case "test":
      return test;
    default:
      break;
  }
};

export const env = getEnv();
