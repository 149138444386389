import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  AUTHENTICATED_ENTRY_SHIPPER,
  AUTHENTICATED_ENTRY_CARRIER,
  AUTHENTICATED_ENTRY_ADMIN,
} from "configs/AppConfig";

const PublicRoute = () => {
  const type = useSelector((state) => state.auth.user_base_type);
  const { token } = useSelector((state) => state.auth);

  return token && type === "shipper" ? (
    <Navigate to={AUTHENTICATED_ENTRY_SHIPPER} />
  ) : token && type === "carrier" ? (
    <Navigate to={AUTHENTICATED_ENTRY_CARRIER} />
  ) : token && type === "admin" ? (
    <Navigate to={AUTHENTICATED_ENTRY_ADMIN} />
  ) : (
    <Outlet />
  );
};

export default PublicRoute;
