import fetch from "auth/FetchInterceptor";

const AuthService = {};

AuthService.login = function (data) {
  return fetch({
    url: "/login",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
    redirect: "follow",
  });
};

export default AuthService;
