import React from "react";
import { Routes as RouterRoutes, Route, Navigate } from "react-router-dom";
import {
  AUTHENTICATED_ENTRY_SHIPPER,
  AUTHENTICATED_ENTRY_CARRIER,
  AUTHENTICATED_ENTRY_ADMIN,
} from "configs/AppConfig";
import { protectedRoutes, publicRoutes } from "configs/RoutesConfig";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import AppRoute from "./AppRoute";
import { useSelector } from "react-redux";

const Routes = () => {
  const type = useSelector((state) => state.auth.user_base_type);

  // Determine the authenticated entry point based on user type
  let authenticatedEntry = "/";
  switch (type) {
    case "shipper":
      authenticatedEntry = AUTHENTICATED_ENTRY_SHIPPER;
      break;
    case "carrier":
      authenticatedEntry = AUTHENTICATED_ENTRY_CARRIER;
      break;
    case "admin":
      authenticatedEntry = AUTHENTICATED_ENTRY_ADMIN;
      break;
    default:
      authenticatedEntry = "/";
      break;
  }

  return (
    <RouterRoutes>
      <Route path='/' element={<ProtectedRoute />}>
        {type ? (
          <>
            <Route
              path='/'
              element={
                <Navigate key='redirect' replace to={authenticatedEntry} />
              }
            />

            {protectedRoutes.map((route, index) => {
              return (
                <Route
                  key={route.key + index}
                  path={route.path}
                  element={
                    <AppRoute
                      routeKey={route.key}
                      component={route.component}
                      {...route.meta}
                    />
                  }
                />
              );
            })}
          </>
        ) : (
          // Unauthenticated or invalid type: Redirect to public routes
          <Route path='/' element={<Navigate to='/' replace />} />
        )}
        <Route path='*' element={<Navigate to='/' replace />} />
      </Route>
      <Route path='/' element={<PublicRoute />}>
        {publicRoutes.map((route) => {
          return (
            <Route
              key={route.path}
              path={route.path}
              element={
                <AppRoute
                  routeKey={route.key}
                  component={route.component}
                  {...route.meta}
                />
              }
            />
          );
        })}
      </Route>
    </RouterRoutes>
  );
};

export default Routes;
