import React, { lazy, Suspense, memo } from "react";
import { useSelector } from "react-redux";
import { ConfigProvider } from "antd";
import Loading from "components/shared-components/Loading";
import { lightTheme, darkTheme } from "configs/ThemeConfig";
import { resources } from "lang";
import useBodyClass from "utils/hooks/useBodyClass";
import Routes from "routes";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { useLocation } from "react-router-dom";

const AppLayout = lazy(() => import("./AppLayout"));
const AuthLayout = lazy(() => import("./AuthLayout"));
const SetupLayout = lazy(() => import("./SetupLayout"));
const ErrorLayout = lazy(() => import("./ErrorLayout"));
const Layouts = () => {
  const token = useSelector((state) => state.auth.token);
  const setup = useSelector((state) => state.auth.completed_profile);
  const is_verified = useSelector((state) => state.auth.is_verified);
  const blankLayout = useSelector((state) => state.theme.blankLayout);
  const location = useLocation();
  const shouldRenderLayout = !location.pathname.startsWith(
    `${APP_PREFIX_PATH}/error/`
  );
  //If user is not setuped then change to the setup layout
  const Layout = !shouldRenderLayout
    ? ErrorLayout // Render nothing for error paths
    : token && !blankLayout && (setup === false || is_verified === false)
    ? SetupLayout
    : token && !blankLayout
    ? AppLayout
    : AuthLayout;

  const locale = useSelector((state) => state.theme.locale);

  const direction = useSelector((state) => state.theme.direction);

  const currentTheme = useSelector((state) => state.theme.currentTheme);

  const currentAppLocale = resources[locale];

  useBodyClass(`dir-${direction}`);

  const themeConfig =
    currentTheme === "light" ? { ...lightTheme } : { ...darkTheme };

  return (
    <ConfigProvider
      theme={themeConfig}
      direction={direction}
      locale={currentAppLocale.antd}
    >
      <Suspense fallback={<Loading cover='content' />}>
        <Layout>
          <Routes />
        </Layout>
      </Suspense>
    </ConfigProvider>
  );
};

export default memo(Layouts);
