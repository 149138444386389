export const AUTH_TOKEN = "auth_token";
export const USER_BASE_TYPE = "user_base_type";
export const COMPLETED_PROFILE = "completed_profile";
export const USER_ORGANIZATION_TYPE = "user_organization_type";
export const USER_TYPE = "user_type";
export const USER_ID = "user_id";
export const USER_EMAIL = "user_email";
export const COMPANY_SHIPPER_ID = "company_shipper_id";
export const INDIVIDUAL_SHIPPER_ID = "individual_shipper_id";
export const COMPANY_CARRIER_ID = "company_carrier_id";
export const INDIVIDUAL_CARRIER_ID = "individual_carrier_id";
export const IS_VERIFIED = "is_verified";
