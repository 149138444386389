import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  AUTH_TOKEN,
  USER_ORGANIZATION_TYPE,
  USER_BASE_TYPE,
  COMPLETED_PROFILE,
  USER_TYPE,
  USER_ID,
  USER_EMAIL,
  IS_VERIFIED,
  COMPANY_SHIPPER_ID,
  INDIVIDUAL_SHIPPER_ID,
  COMPANY_CARRIER_ID,
  INDIVIDUAL_CARRIER_ID,
} from "constants/AuthConstant";
import FirebaseService from "services/FirebaseService";
import AuthService from "services/AuthService";

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  finalize_setup: false,
  company_shipper_id: localStorage.getItem(COMPANY_SHIPPER_ID) || null,
  individual_shipper_id: localStorage.getItem(INDIVIDUAL_SHIPPER_ID) || null,
  company_carrier_id: localStorage.getItem(COMPANY_CARRIER_ID) || null,
  individual_carrier_id: localStorage.getItem(INDIVIDUAL_CARRIER_ID) || null,
  user_id: localStorage.getItem(USER_ID) || null,
  user_email: localStorage.getItem(USER_EMAIL) || null,
  is_verified: JSON.parse(localStorage.getItem(IS_VERIFIED) || null),
  user_type: localStorage.getItem(USER_TYPE) || null,
  token: localStorage.getItem(AUTH_TOKEN) || null,
  user_base_type: localStorage.getItem(USER_BASE_TYPE) || null,
  user_organization_type: localStorage.getItem(USER_ORGANIZATION_TYPE) || null,
  completed_profile: JSON.parse(
    localStorage.getItem(COMPLETED_PROFILE) || null
  ),
};

export const signIn = createAsyncThunk(
  "/auth/login",
  async (data, { rejectWithValue }) => {
    const { email, password } = data;
    try {
      const response = await AuthService.login({ email, password });
      const {
        token,
        is_verified,
        user,
        user_id,
        user_type,
        user_email,
        company_shipper_id,
        individual_shipper_id,
        company_carrier_id,
        individual_carrier_id,
        user_base_type,
        user_organization_type,
        completed_profile,
      } = response;

      if (
        user.user_type === "company_shipper" &&
        user.completed_profile === true
      ) {
        localStorage.setItem(COMPANY_SHIPPER_ID, user.CompanyShipper.id);
        localStorage.setItem(INDIVIDUAL_SHIPPER_ID, null);
      } else if (
        user.user_type === "individual_shipper" &&
        user.completed_profile === true
      ) {
        localStorage.setItem(INDIVIDUAL_SHIPPER_ID, user.IndividualShipper.id);
        localStorage.setItem(COMPANY_SHIPPER_ID, null);
      }
      if (
        user.user_type === "company_carrier" &&
        user.completed_profile === true
      ) {
        localStorage.setItem(COMPANY_CARRIER_ID, user.CompanyCarrier.id);
        localStorage.setItem(INDIVIDUAL_CARRIER_ID, null);
      } else if (
        user.user_type === "individual_carrier" &&
        user.completed_profile === true
      ) {
        localStorage.setItem(INDIVIDUAL_CARRIER_ID, user.IndividualCarrier.id);
        localStorage.setItem(COMPANY_CARRIER_ID, null);
      }
      localStorage.setItem(USER_TYPE, user.user_type);
      localStorage.setItem(USER_ID, user.id);
      localStorage.setItem(USER_EMAIL, user.email);
      localStorage.setItem(AUTH_TOKEN, token);
      localStorage.setItem(USER_BASE_TYPE, user.user_base_type);
      localStorage.setItem(USER_ORGANIZATION_TYPE, user.user_organization_type);
      localStorage.setItem(COMPLETED_PROFILE, user.completed_profile);
      localStorage.setItem(IS_VERIFIED, user.is_verified);
      return {
        token,
        user,
        user_type,
        user_id,
        is_verified,
        user_email,
        user_base_type,
        company_shipper_id,
        individual_shipper_id,
        individual_carrier_id,
        company_carrier_id,
        user_organization_type,
        completed_profile,
      };
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response?.data?.message || "Error1");
    }
  }
);

export const signUp = createAsyncThunk(
  "auth/register",
  async (data, { rejectWithValue }) => {
    const { email, password } = data;
    try {
      const response = await AuthService.register({ email, password });
      const token = response.data.token;
      localStorage.setItem(AUTH_TOKEN, token);
      return token;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const signOut = createAsyncThunk("auth/logout", async () => {
  const response = await FirebaseService.signOutRequest();
  localStorage.removeItem(USER_BASE_TYPE);
  localStorage.removeItem(COMPANY_SHIPPER_ID);
  localStorage.removeItem(INDIVIDUAL_SHIPPER_ID);
  localStorage.removeItem(COMPANY_CARRIER_ID);
  localStorage.removeItem(INDIVIDUAL_CARRIER_ID);
  localStorage.removeItem(USER_TYPE);
  localStorage.removeItem(USER_ID);
  localStorage.removeItem(IS_VERIFIED);
  localStorage.removeItem(USER_EMAIL);
  localStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItem(USER_ORGANIZATION_TYPE);
  localStorage.removeItem(COMPLETED_PROFILE);
  return response.data;
});

export const signInWithGoogle = createAsyncThunk(
  "auth/signInWithGoogle",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AuthService.loginInOAuth();
      const token = response.data.token;
      localStorage.setItem(AUTH_TOKEN, token);
      return token;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const signInWithFacebook = createAsyncThunk(
  "auth/signInWithFacebook",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AuthService.loginInOAuth();
      const token = response.data.token;
      localStorage.setItem(AUTH_TOKEN, token);
      return token;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authenticated: (state, action) => {
      state.loading = false;
      state.redirect = "/";
      state.token = action.payload;
      state.user_base_type = action.payload;
      state.completed_profile = action.payload;
      state.user_organization_type = action.payload;
    },
    showAuthMessage: (state, action) => {
      state.message = action.payload;
      state.showMessage = true;
      state.loading = false;
    },
    hideAuthMessage: (state) => {
      state.message = "";
      state.showMessage = false;
    },
    signOutSuccess: (state) => {
      state.loading = false;
      state.token = null;
      state.user_base_type = null;
      state.user_organization_type = null;
      state.redirect = "/";
    },
    showLoading: (state) => {
      state.loading = true;
    },
    signInSuccess: (state, action) => {
      state.loading = false;
      state.token = action.payload;
      state.user_base_type = action.payload;
      state.completed_profile = action.payload;
      state.user_organization_type = action.payload;
    },
    setFinalizeSetup: (state) => {
      state.finalize_setup = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.loading = true;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.loading = false;
        // Assign token,type,setup,userType from payload
        state.token = action.payload.token;
        state.company_shipper_id = localStorage.getItem(COMPANY_SHIPPER_ID);
        state.individual_shipper_id = localStorage.getItem(
          INDIVIDUAL_SHIPPER_ID
        );
        state.company_carrier_id = localStorage.getItem(COMPANY_CARRIER_ID);
        state.individual_carrier_id = localStorage.getItem(
          INDIVIDUAL_CARRIER_ID
        );
        state.user_email = action.payload.user.email;
        state.user_id = action.payload.user.id;
        state.user_type = action.payload.user.user_type;
        state.user_base_type = action.payload.user.user_base_type;
        state.completed_profile = action.payload.user.completed_profile;
        state.is_verified = action.payload.user.is_verified;
        state.user_organization_type =
          action.payload.user.user_organization_type;
        state.redirect = "/";
      })
      .addCase(signIn.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signOut.fulfilled, (state) => {
        state.loading = false;
        state.token = null;
        state.finalize_setup = false;
        state.redirect = "/";
      })
      .addCase(signOut.rejected, (state) => {
        state.loading = false;
        state.user_base_type = null;
        state.user_id = null;
        state.user_email = null;
        state.user_type = null;
        state.token = null;
        state.company_shipper_id = null;
        state.individual_shipper_id = null;
        state.company_carrier_id = null;
        state.individual_carrier_id = null;
        state.completed_profile = null;
        state.is_verified = null;
        state.user_organization_type = null;
        state.finalize_setup = false;
        state.redirect = "/";
      })
      .addCase(signUp.pending, (state) => {
        state.loading = true;
      })
      .addCase(signUp.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload;
      })
      .addCase(signUp.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signInWithGoogle.pending, (state) => {
        state.loading = true;
      })
      .addCase(signInWithGoogle.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload;
      })
      .addCase(signInWithGoogle.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signInWithFacebook.pending, (state) => {
        state.loading = true;
      })
      .addCase(signInWithFacebook.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload;
      })
      .addCase(signInWithFacebook.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      });
  },
});

export const {
  authenticated,
  showAuthMessage,
  hideAuthMessage,
  signOutSuccess,
  showLoading,
  signInSuccess,
  setFinalizeSetup,
} = authSlice.actions;

export default authSlice.reducer;
